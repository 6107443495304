import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import PublishIcon from '@material-ui/icons/Publish';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
    },
    root: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(8, 8, 3),
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

export default function CustomizedTimeline() {
    const classes = useStyles();

    return (
        <Timeline align="alternate" className={classes.root}>

            <TimelineItem>

                <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                        v2.2.0
                    </Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                    <TimelineDot color="primary">
                        <LaptopMacIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>

                        <Typography variant="h6" component="h1">
                            Changelog
                        </Typography>

                        <Typography>- Melhoria em mensagem de sincronização quando a sessão do WhatsApp perdia a conexão ou tinha falha fazia com que as próximas mensagens sincronizadas cria-sem mais atendimentos únicos.</Typography>
                        <Typography>- Adicionado campo para definir mensagem personalizada ao ignorar chamadas.</Typography>
                        </Paper>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                        v2.1.0
                    </Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                    <TimelineDot color="primary">
                        <LaptopMacIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>

                        <Typography variant="h6" component="h1">
                            Changelog
                        </Typography>

                        <Typography>- Melhoria da api-whatsapp, adicionado modelo cache para garantir que mensagens sejam reprocessadas.</Typography>
                        <Typography>- Melhoria de versionamento interno e tratamento de erros do lado do backend.</Typography>
                        <Typography>- Melhorias de cores e layout.</Typography>
                        <Typography>- Versão v3.0.0 já em desenvolvimento, novo layout e template embreve.</Typography>
                        <Typography>- Correcao de importação e sincronização de contatos, anteriormente estourava o uso de memória</Typography>
                        <Typography>- Remoção de pacotes desatualizados.</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                        v2.0.0
                    </Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                    <TimelineDot color="primary">
                        <LaptopMacIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>

                        <Typography variant="h6" component="h1">
                            Changelog
                        </Typography>

                        <Typography>- Corrigido integrações, repetiam mensagem de erro ao concluir a entrega da resposta.</Typography>
                        <Typography>- Adicionado opção desativar chatbot do contato.</Typography>
                        <Typography>- Melhorias na tela de login.</Typography>

                    </Paper>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>

                <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                        v1.9.0
                    </Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                    <TimelineDot color="primary">
                        <LaptopMacIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>

                        <Typography variant="h6" component="h1">
                            Changelog
                        </Typography>

                        <Typography>- Corrigidas variáveis e quantidade de caracteres nas mensagens.</Typography>
                        <Typography>- Corrigida a importação de contatos.</Typography>
                        <Typography>- Corrigido um bug ao salvar novo contato.</Typography>
                        <Typography>- Corrigido bug ao receber ligação; não imprimia o log. Agora é possível receber a ligação através de qualquer plataforma WhatsApp.</Typography>
                        <Typography>- Adicionada nova aba de grupos, onde é possível ter um melhor gerenciamento de atendimentos em grupos do WhatsApp.</Typography>
                        <Typography>- Adicionada opção de finalizar sem enviar mensagem de despedida.</Typography>
                        <Typography>- Adicionadas algumas melhorias e correções no layout e traduções.</Typography>

                    </Paper>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>

                <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                        v1.8.0
                    </Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                    <TimelineDot color="primary">
                        <LaptopMacIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>

                        <Typography variant="h6" component="h1">
                            Changelog
                        </Typography>

                        <Typography>- Atualizado arquivo de atualização rapida.</Typography>
                        <Typography>- Atualizado imagens do perfil de contato aparecem como HD.</Typography>
                        <Typography>- Adicionado campo de variaveis disponiveis em respostas rapidas.</Typography>
                        <Typography>- Corrigido recebimento de arquivos com texto ou emojis.</Typography>
                        <Typography>- Adicionado opção de recarregar página dentro da aplicação.</Typography>
                        <Typography>- Adicionado mensagem de boas vindas até 7 dias.</Typography>
                        <Typography>- Adicionado algumas melhorias e correções no layout.</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                        v1.7.0
                    </Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                    <TimelineDot color="primary">
                        <LaptopMacIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>

                        <Typography variant="h6" component="h1">
                            Changelog
                        </Typography>

                        <Typography>- Atualizado metodo de pesquisa de resposta rapida.</Typography>
                        <Typography>- Habilitado para que qualquer usuario consiga trocar sua propria senha.</Typography>
                        <Typography>- Melhorado filas e chatbots, agora e possivel adicionar multi linhas na opção</Typography>
                        <Typography>- Adicionado integracoes com IXC, MK-AUTH e ASAAS.</Typography>

                    </Paper>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                        v1.6.0
                    </Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                    <TimelineDot color="primary">
                        <LaptopMacIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>

                        <Typography variant="h6" component="h1">
                            Changelog
                        </Typography>

                        <Typography>- Adicionado pagina de Tokens e Documentação da API.</Typography>
                        <Typography>- Adicionado opção dos tickets serem finalizados diretamente quando vindo por API.</Typography>
                        <Typography>- Adicionado mensagem de saudação da fila como obrigatória ao ser criada.</Typography>
                        <Typography>- Corrigido erro ao acessar a pagina de login.</Typography>

                    </Paper>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                        v1.5.1
                    </Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                    <TimelineDot>
                        <LaptopMacIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>

                        <Typography variant="h6" component="h1">
                            Changelog
                        </Typography>

                        <Typography>- Corrigido erro de leitura do campo mensagem de transferência de Ticket.</Typography>
                        <Typography>- Corrigido icones do status de usuarios.</Typography>

                    </Paper>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                        v1.5.0
                    </Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                    <TimelineDot color="primary">
                        <LaptopMacIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>

                        <Typography variant="h6" component="h1">
                            Changelog
                        </Typography>

                        <Typography>- Adicionado mensagem ao transferir de fila.</Typography>
                        <Typography>- Corrigido erro ao receber dois arquivos com o mesmo nome.</Typography>

                    </Paper>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                        v1.4.0
                    </Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                    <TimelineDot color="primary">
                        <LaptopMacIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>

                        <Typography variant="h6" component="h1">
                            Changelog
                        </Typography>

                        <Typography>- Adicionado Opção de Ativar e Inativar usuarios.</Typography>
                        <Typography>- Melhorado script de atualizações.</Typography>
                        <Typography>- Atualizado traduções.</Typography>
                        <Typography>- Melhorado desempenho da conexão WhatsApp API para que não fique somente aparecendo o reloginho nas mensagens.</Typography>

                    </Paper>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                        v1.3.0
                    </Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                    <TimelineDot color="primary">
                        <LaptopMacIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>

                        <Typography variant="h6" component="h1">
                            Changelog
                        </Typography>

                        <Typography>- Melhorado Tela de Atualizações.</Typography>
                        <Typography>- Criado opção de limpar a sessão da conexão através da interface, sem necessidade de deletar a conexão.</Typography>
                        <Typography>- Atualizado videos demostrativos na pagina de apoio.</Typography>
                        <Typography>- Alterado a marcação de visualização, agora só e marcado visualizado a mensagem do cliente caso seja respondido.</Typography>
                        <Typography>- Fixado a função de Multidevice na página de WhatsApp.</Typography>
                        <Typography>- Adicionado melhoria na API.</Typography>

                    </Paper>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                        v1.2.0
                    </Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                    <TimelineDot >
                        <LaptopMacIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>

                        <Typography variant="h6" component="h1">
                            Changelog
                        </Typography>

                        <Typography>- Remoção da opção de criar usuário através da tela de login.</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                        v1.1.1
                    </Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                    <TimelineDot>
                        <LaptopMacIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>

                        <Typography variant="h6" component="h1">
                            Changelog
                        </Typography>

                        <Typography>- Correção na tradução para o inglês, onde não estava sendo direcionado os novos textos para a página de Dashboard.</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                        v1.1.0
                    </Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                    <TimelineDot color="primary">
                        <LaptopMacIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>

                        <Typography variant="h6" component="h1">
                            Changelog
                        </Typography>

                        <Typography>- Criado linha do tempo para atualizações.</Typography>
                        <Typography>- Criado opção de atualizar versão através da interface.</Typography>
                        <Typography>- Correção no direcionamento da API WhatsApp.</Typography>
                        <Typography>- Incluído a integração oficial do Facebook Messenger e Instagram.</Typography>
                        <Typography>- Criado opção de reiniciar os servidor através da interface.</Typography>
                        <Typography>- Fixado erro de inserção de key quando era criado um novo ticket no banco de dados.</Typography>

                    </Paper>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>

                <TimelineSeparator>
                    <TimelineDot color="secondary">
                        <PublishIcon />
                    </TimelineDot>
                </TimelineSeparator>

                <TimelineContent></TimelineContent>
            </TimelineItem>

        </Timeline>
    );
}